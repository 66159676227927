import appConstants from "../app.constants";

export function getLocale () {
    if (navigator.languages && navigator.languages.length > 0) {
        return navigator.languages[0]
    }
    return navigator.language;
}

let languageIso = '';
export function getCurrentLanguageIsoCode () {
    if (!languageIso) {
        languageIso = document.documentElement.lang ?? appConstants.DEFAULT_LANG_ISO_CODE;
    }
    return languageIso;
}