import errorMessages from "../error-messages";
import axios from "axios";

export function getErrorMessage (error: any) {
    let errorMessage = errorMessages.UNEXPECTED_ERROR;
    if (axios.isAxiosError(error) && error.response) {
        const response = error.response;
        if (Array.isArray(response?.data.messages)) {
            errorMessage = response.data.messages[0] || errorMessage;
        }
        if (response.status === 401) {
            errorMessage = errorMessages.AUTH_EXPIRED;
        }
    }
    return errorMessage;
}