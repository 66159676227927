import { ref } from 'vue';
import { useNotifications } from './use-notifications';
import { getErrorMessage as getErrorMessageUtil } from '@/modules/core/utilities';
import axios, { type AxiosError } from 'axios';

const isActionInProgress = ref(false);
const { showToast } = useNotifications();

export async function useErrorHandling<T> (
    promise: Promise<T> | ((...args: any[]) => Promise<T>),
    getErrorMessage = getErrorMessageUtil,
    dontThrowError = false
) {
    try {
        isActionInProgress.value = true;
        if (typeof promise === 'function') return await promise();
        return await promise;
    } catch (error: any) {
        const isRequestCancellation = error.name === 'CanceledError' || error.message === 'canceled';
        let errorMessage = getErrorMessage(error);
        const suppressToast = isRequestCancellation || axios.isAxiosError(error) && error.config?.suppressAuthCheckRedirect;
        if (!suppressToast) {
            showToast({ message: errorMessage, type: 'danger' })
        }
        if(!dontThrowError) {
            throw error;
        } else {
            return (error as AxiosError<T>).response as T;
        }
    } finally {
        isActionInProgress.value = false;
    }
}