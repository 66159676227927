import { getLocale } from "./i18n";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)

export function formatAsCurrencyString (value?: number, currencyName = 'MYR', currencyDisplay = 'symbol', removeDecimal = false) {
    if (typeof value === 'undefined') return '';
    
    const formatter = Intl.NumberFormat(
        getLocale(),
        {
            currency: currencyName,
            currencyDisplay,
            style: 'currency',
            ...(removeDecimal && {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            })
        }
    )
    return formatter.format(value)
}

export function formatSubscriptionPlanPrice(price: number, currencyName = 'EUR') {
    let removeDecimal = false;
    if(price % 1 === 0) removeDecimal = true;
    return formatAsCurrencyString(price, currencyName, 'narrowSymbol', removeDecimal);
}

export function formatNumberWithCommas(value: number) {
    return Intl.NumberFormat(getLocale()).format(value);
}