import debounce from 'lodash/debounce';

export function setCssHelperVars () {
    const run = () => {
        const navHeight = document.querySelector('.navbar-main')?.clientHeight || 0;
        const contentMinFullHeight = (window.innerHeight ?? 0) - navHeight;
        document.documentElement.style.setProperty('--nav-height', navHeight + 'px');
        document.documentElement.style.setProperty('--content-min-full-height', contentMinFullHeight + 'px');
        setScrollbarWidthCssVar()
    }

    window.addEventListener('resize', debounce(run, 300));
    run();
}

export function setScrollbarWidthCssVar () {
    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    // Removing temporary elements from the DOM
    if (outer.parentNode) {
        outer.parentNode.removeChild(outer);
    }

    document.documentElement.style.setProperty('--scrollbar-width', scrollbarWidth + 'px');
}

export function remToPixels(value: number) {    
    return value * parseFloat(getComputedStyle(document.documentElement).fontSize);
}