import dayjs from "dayjs";
import LocaleData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(LocaleData);
dayjs.extend(utc);
dayjs.extend(LocalizedFormat);

export function getMonths () {
    return ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'];
}

export function formatDateAsString (date: string|Date, formattingToken: string, isUTC = true) {
    if (!isUTC) return dayjs(date).format(formattingToken)
    if (typeof date === 'string' && !date.endsWith('Z')) {
        date = date + 'Z';
    }
    return dayjs(date).local().format(formattingToken)
}